import {useState} from 'react'
import {useForm} from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

import {getStatistics} from 'src/utils/endpoints'
import {Dates} from 'src/components'
import GraphIcon from 'src/assets/images/icons/graph-icon.webp'

interface Response {
  year: number
  month: number
  allTime: number
  period: number | null
}

const VisitUsers = () => {
  const {handleSubmit, control, reset, watch} = useForm<SendDataForGetStatistics>()
  const theme = useTheme()
  const [formData, setFormData] = useState<SendDataForGetStatistics>()
  const minDate = watch('dateFrom')
  
  const {data, isLoading} = useQuery<Response>(
    ['analytic', formData?.dateTo],
    async () => {
      return (await axios.post(getStatistics, {...formData})).data
    },
  )

  const fields = [
    {
      title: 'За текущий год',
      data: data?.year,
    },
    {
      title: 'За текущий месяц',
      data: data?.month,
    },
    {
      title: 'За все время',
      data: data?.allTime,
    },
    {
      title: 'За выбранный период',
      data: data?.period,
    },
  ]

  return (
    <Box component={'form'} onSubmit={handleSubmit(setFormData)}>
      <Box
        sx={{
          display: 'flex',
          m: '20px 0 50px 0',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '20px',
          alignItems: 'center',
        }}
      >
        <Dates
          control={control}
          registerText={'dateFrom'}
          label={'c'}
          max={new Date()}
          min={String(Date.now())}
          required={true}
        />
        <Dates
          control={control}
          registerText={'dateTo'}
          max={new Date()}
          min={minDate ?? ''}
          disabled={minDate ? false : true}
          label={'по'}
          required={true}
        />
        <Button
          variant={'contained'}
          onClick={() => reset()}
          sx={buttons}
          color={'error'}
          endIcon={<ClearIcon />}
        >
          Очистить
        </Button>
        <Button
          type={'submit'}
          variant={'contained'}
          sx={buttons}
          endIcon={<QueryStatsIcon />}
        >
          Сгенерировать статистику
        </Button>
      </Box>
      {isLoading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '40px',
          }}
        >
          {fields.map(
            (i) =>
              i.data && (
                <Paper
                  sx={{
                    width: '340px',
                    height: '230px',
                    alignItems: 'center',
                    display: 'flex',
                    borderRadius: '8px',
                    backgroundImage: `url(${GraphIcon})`,
                    backgroundSize: 'cover',
                  }}
                  key={i.title}
                >
                  <Box pl='30px'>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: '40px',
                        lineHeight: '40px',
                      }}
                    >
                      {i.data}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: '18px',
                        mt: '5px',
                      }}
                    >
                      {i.title}
                    </Typography>
                  </Box>
                </Paper>
              ),
          )}
        </Box>
      )}
    </Box>
  )
}

export default VisitUsers

const buttons = (theme: Theme) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    width: '80%',
  },
})
