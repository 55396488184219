import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import RepartitionIcon from '@mui/icons-material/Repartition'
import StayPrimaryLandscapeIcon from '@mui/icons-material/StayPrimaryLandscape'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

import {Loader, ScrollTop} from 'src/utils'
import {getDocumentByCode, getEditionCode} from 'src/utils/endpoints'
import {ChangeLang, useAppSelector, useDebounce} from 'src/hooks'
import {IDoc, TEdition} from 'src/models/doc'

import SelectedModal from '../document-methods/selected-modal'

import {ControlDoc} from './control-doc'
import {Expired} from './expired'
import {SearchText} from './search-text'

interface Props {
  loading: boolean
  findDocRu?: string
  findDocKg?: string
  isExpired?: boolean
  refDoc: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLIFrameElement | null>>
  >
  setTextHighlanderToPrint?: (arg?: any) => void
  edition: TEdition
  doc: IDoc
  id?: string | undefined
  searchWord: string
  docScroll: boolean
}

export const Document = ({
  loading,
  edition,
  refDoc,
  searchWord,
  doc,
  docScroll,
  setTextHighlanderToPrint,
}: Props) => {
  const byText = useDebounce(searchWord)
  const {id} = useParams()
  const text = localStorage.getItem('search-by-text')
  const {t} = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)
  const [openLinkModal, setOpenLinkModal] = React.useState<boolean>(false)
  const [textError, setTextError] = useState('')
  const [article, setArticle] = useState<string | undefined>(undefined)
  const [secondDocModal, setSecondDocModal] = useState<boolean>(false)
  const currentWord = byText ? byText : text
  const theme = useTheme()
  const [activeLink, setActiveLink] = useState<string | null>(null)
  const navigate = useNavigate()
  const {lng} = useAppSelector((state) => state.changeLang)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  // const printRef = useRef<HTMLIFrameElement | null>(null)
  const ConditionComponent: React.ElementType = secondDocModal ? Box : Container
  const queryParams = new URLSearchParams(window.location.search)
  const anchor = queryParams.get('anchor')
  const nameDoc = ChangeLang(doc?.nameRus, doc?.nameKyr)

  useEffect(() => {
    if (
      (activeLink && !activeLink?.includes('http')) ||
      activeLink?.includes(id as string)
    ) {
      window.open(`?anchor=${activeLink?.split('#')[1]}`, '_self')
    } else if (activeLink) {
      setOpenLinkModal(true)
    }
  }, [activeLink])

  let refId = undefined
  let selectedLink = activeLink
  ?.replace('http://pilotcbd.minjust.gov.kg/', '')
  ?.replace('http://cbd.minjust.gov.kg/', '')
  .replace('http://85.113.17.179/', '')

  if (selectedLink?.includes('?refId=')) {
    const split = selectedLink.split('?refId=')
    refId = split[1]
  }

  if (selectedLink?.includes('?')) {
    const split = selectedLink?.split('?')
    selectedLink = split[0]
    if (split[1]?.includes('#')) {
      const hashSplitted = split[1].split('#')
      selectedLink = `${selectedLink}?anchor=${hashSplitted[1]}`
    }
  } else {
    selectedLink = selectedLink?.replace(/#/g, '?anchor=')
  }

  const finalLink = refId ? `${selectedLink}/editon/${refId}` : selectedLink
  const newWindow = () => {
    let link = finalLink
    if (link?.includes('?anchor')) {
      link = `${link}&lang=${lng}`
    } else {
      link = `${finalLink}?lang=${lng}`
    }

    window.open(`${link}`, '_blank')
    setActiveLink(null)
    setOpenLinkModal(false)
  }

  // useEffect(() => {
  //   const iframe = iframeRef.current
  //   if (iframe) {
  //     const iframeWindow = iframe.contentWindow as Window | null
  //     // setTextHighlanderToPrint(iframeWindow)
  //   }
  //   refDoc(iframeRef)
  // }, [])

  const handleGetSelectedText = () => {
    const iframe = iframeRef.current
    if (iframe) {
      const iframeWindow = iframe.contentWindow as Window | null

      if (iframeWindow) {
        const selection = iframeWindow.getSelection()
        if (selection?.toString()) {
          setTextError(selection.toString())
          // setTextHighlanderToPrint(selection.toString())
          setOpen(true)
          return selection.toString()
        }
      }
    }
  }

  const textdown = () => {
    const iframe = iframeRef.current
    if (iframe) {
      const iframeWindow = iframe.contentWindow as Window | null

      if (iframeWindow) {
        const selection = iframeWindow.getSelection()
        if (selection?.toString()) {
          const element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(selection.toString()));
          element.setAttribute('download', nameDoc);

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        }
      }
    }
  }
  const slink = selectedLink
  ?.replace('https://cbd.minjust.gov.kg/', '')
  ?.replace('http://cbd.minjust.gov.kg/', '')
  ?.replace('act/view/ru-ru/', '')
  .split('?')[0]
  .split('/')[0]
  
  const {data: secondDocId, refetch} = useQuery(
    ['second-doc'],
    async () => {
      if(slink?.match(/^[0-9]+(-[0-9]+)*$/) && Number(slink.replace(/-/g, '')) <= 2147483647) {
        const docInfo = (
          await axios.get<IDoc>(
            `${getDocumentByCode}${
              slink
            }`,
          )
        ).data
        const docID = docInfo ? docInfo.editions.at(-1)?.id : 0
  
        if (docID) {
          if (selectedLink?.includes('?anchor=')) {
            setArticle(selectedLink?.split('?anchor=')[1])
          }
          setSecondDocModal(true)
          setOpenLinkModal(false)
          setActiveLink(null)
  
          return docID
        }
      }
    },
    {enabled: false, refetchOnWindowFocus: false, refetchOnMount: false},
  )

  const {data: secondDoc, isFetching} = useQuery<TEdition>(
    ['second-doc', lng, secondDocId, article],
    async () => {
      return (await axios.get(`${getEditionCode}${secondDocId}&lang=${lng}`))
        .data
    },
    {
      enabled: !!secondDocId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const onLoadFirstIframe = useCallback(() => {
    const frame = document.getElementById('first-doc') as HTMLIFrameElement
    const printDoc = document.getElementById('printDoc') as HTMLIFrameElement
    const iframeContent = frame?.contentDocument
    const link = document.getElementById('uniq-anchor') as HTMLInputElement

    if (link?.value.length) {
      navigate({search: `anchor=${link.value}`})
    }

    if (anchor?.length && iframeContent?.getElementsByName(anchor)[0]) {
      setTimeout(() => {
        iframeContent?.getElementsByName(anchor)[0].scrollIntoView()
      }, 0)
    }

    // printDoc.style.height = iframeContent?.documentElement.scrollHeight
    //   ? iframeContent?.documentElement.scrollHeight + 30 + 'px'
    //   : ''
    frame.style.height = secondDocModal
      ? '92vh'
      : iframeContent?.documentElement.scrollHeight
      ? iframeContent?.documentElement.scrollHeight + 30 + 'px'
      : ''
    findActualLink(iframeContent)
  }, [secondDocModal])
  const onLoadSecondIframe = () => {
    const iframe = document.getElementById('second-doc') as HTMLIFrameElement
    const iframeContent = iframe?.contentDocument
    findActualLink(iframeContent)
    scrollSecondDoc(iframeContent)
  }
  const findActualLink = (iframe: Document | null) =>
    iframe?.addEventListener('click', function (event: MouseEvent) {
      const target = event?.target as HTMLElement

      const _A = target?.nodeName === 'A'
      const _SPAN = target?.parentNode?.nodeName === 'A'
      const _I = target?.parentNode?.parentNode?.nodeName === 'A'
      if (_A || _SPAN || _I) {
        const parentAnchor: any = _A
          ? target
          : _SPAN
          ? target?.parentNode
          : _I
          ? target.parentNode?.parentNode
          : null
        setActiveLink(parentAnchor?.getAttribute('href'))
        event.preventDefault()
      }
    })

  const scrollSecondDoc = (iframe: Document | null) => {
    if (iframe && article) {
      const element = iframe?.getElementsByName(article)[0]
      setTimeout(() => {
        element?.scrollIntoView()
      }, 100)
    }
  }

  return (
    <>
      <ScrollTop />
      <Divider />
      <SelectedModal
        open={open}
        doc={doc}
        handleClose={() => setOpen(false)}
        textError={textError}
      />
      <>
        {loading ? (
          <Box sx={{textAlign: 'center', marginTop: '100px'}}>
            <Loader size={90} />
          </Box>
        ) : edition ? (
          <>
            {edition?.contentRu ? (
              <Box sx={{display: 'block', position: 'relative'}}>
                <SearchText currentWord={currentWord} />
                <Box
                  className='document-notice'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box textAlign={'center'}>
                    <Expired id={id} doc={doc} />
                    <Box sx={sendError}>
                      <Typography>
                        Ошибка в тексте? <br /> Выделите ее мышкой! <br /> И
                        нажмите:{' '}
                        <span
                          style={{cursor: 'pointer'}}
                          onClick={handleGetSelectedText}
                        >
                          Сюда
                        </span>
                      </Typography>
                      <Divider />
                      <Typography>
                        <span
                          style={{cursor: 'pointer'}}
                          onClick={textdown}
                        >
                          Загрузить цитату
                        </span>
                      </Typography>
                    </Box>
                    <ControlDoc isInControl={doc.isInControl} />
                  </Box>
                </Box>
                <ConditionComponent
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 0,
                  }}
                >
                  {/* <Box display='none'>
                    <iframe
                      scrolling='no'
                      id='printDoc'
                      ref={printRef}
                      style={{
                        width: '100%',
                        border: 'none',
                      }}
                      srcDoc={edition.contentRu}
                    />
                  </Box> */}
                  <iframe
                    id='first-doc'
                    ref={iframeRef}
                    scrolling={docScroll ? 'auto' : 'no'}
                    onLoad={onLoadFirstIframe}
                    style={{
                      width: secondDocModal ? '50%' : '100%',
                      padding: '5px',
                      backgroundColor: 'white',
                      border: 'none',
                    }}
                    srcDoc={
                      !currentWord
                        ? edition?.contentRu
                        : edition.contentRu?.replace(
                            eval('/' + currentWord + '/gi'),
                            (item: string) => {
                              return `<b class='wordSelected' style='color: red; font-weight: bold;'>${item}</b>`
                            },
                          )
                    }
                  />
                  {secondDoc && secondDocModal && (
                    <Box sx={{backgroundColor: 'white', width: '50%'}}>
                      {secondDoc.contentRu ? (
                        <iframe
                          id='second-doc'
                          onLoad={onLoadSecondIframe}
                          style={secondDocStyle}
                          srcDoc={secondDoc?.contentRu}
                        />
                      ) : (
                        <Typography
                          variant={'h5'}
                          sx={{textAlign: 'center', mt: 6}}
                        >
                          {lng === 'ru' ? t('editorial.ru') : t('editorial.kg')}
                        </Typography>
                      )}
                      <IconButton
                        color='primary'
                        aria-label='add to shopping cart'
                        sx={{position: 'absolute', top: '2px', right: '10px'}}
                        onClick={() => setSecondDocModal(false)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Box>
                  )}
                </ConditionComponent>
              </Box>
            ) : (
              <Typography sx={{textAlign: 'center', fontSize: '18px', mt: 10}}>
                {lng === 'ru' ? t('editorial.ru') : t('editorial.kg')}
              </Typography>
            )}
          </>
        ) : (
          <Typography sx={{textAlign: 'center', fontSize: '18px', mt: 10}}>
            {ChangeLang(t('errors.loadDocError'), t('errors.loadDocError'))}
          </Typography>
        )}
      </>
      <>
        {openLinkModal && (
          <>
            <Paper
              sx={{
                position: 'fixed',
                top: '14.5rem',
                zIndex: 1,
                left: '4rem',
                p: 2.5,
                display: 'grid',
                background: theme.palette.primary.main,
              }}
            >
              <Button
                sx={{color: 'white'}}
                onClick={newWindow}
                startIcon={<RepartitionIcon />}
              >
                {lng === 'ru' ? 'Открыть в новом окне' : 'Жаңы терезеде ачуу'}
              </Button>
              <Button
                sx={{color: 'white'}}
                startIcon={<StayPrimaryLandscapeIcon />}
                onClick={() => refetch()}
              >
                {lng === 'ru'
                  ? 'Открыть в текущем окне'
                  : 'Учурдагы терезеде ачуу'}
              </Button>
              <IconButton
                sx={{position: 'absolute', right: 0, color: 'white'}}
                onClick={() => (setOpenLinkModal(false), setActiveLink(null))}
              >
                <ClearIcon />
              </IconButton>
            </Paper>
          </>
        )}
        <Backdrop
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={isFetching}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </>
  )
}

const sendError = (theme: Theme) => ({
  position: 'fixed',
  right: '1rem',
  mt: '-6rem',
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'none',
  },
  backgroundColor: 'rgba(206,206,206,0.41)',
  p: 1,
})

const secondDocStyle = {
  width: '99%',
  padding: '5px',
  border: 'none',
  height: '92vh',
  backgroundColor: 'white',
}
