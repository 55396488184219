// import * as React from 'react'
// import {useTranslation} from 'react-i18next'
// import CloseIcon from '@mui/icons-material/Close'
// import {
//   AppBar,
//   Box,
//   Dialog,
//   Divider,
//   IconButton,
//   Slide,
//   Toolbar,
//   Typography,
// } from '@mui/material'
// import {TransitionProps} from '@mui/material/transitions'
// import {useQuery} from '@tanstack/react-query'
// import axios from 'axios'

// import {compareLang} from 'src/utils/endpoints'

// import {Loader} from '../../../../../utils'

// interface Props {
//   id: number
// }

// interface LangResponse {
//   txt1: string
//   txt2: string
// }

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction='up' ref={ref} {...props} />
// })

// const CompareLanguages = ({id}: Props) => {
//   const [open, setOpen] = React.useState(false)

//   const {data, isLoading} = useQuery<LangResponse>(
//     [id, 'doc'],
//     async () => {
//       return (await axios.get(`${compareLang}${id}`)).data
//     },
//     {enabled: open},
//   )

//   const handleClickOpen = () => {
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }
//   const {t} = useTranslation()

//   return (
//     <>
//       <Typography
//         onClick={handleClickOpen}
//         sx={{
//           p: 1,
//           cursor: 'pointer',
//           '&:hover': {background: 'rgba(227,227,227,0.65)'},
//         }}
//       >
//         {t('Comparison.comparison-of-lang')}
//       </Typography>
//       <Dialog
//         fullScreen
//         open={open}
//         onClose={handleClose}
//         TransitionComponent={Transition}
//       >
//         <AppBar sx={{position: 'relative'}}>
//           <Toolbar>
//             <Typography sx={{ml: 2, flex: 1}} variant='h6' component='div'>
//               {t('Comparison.comparison-of-lang')}
//             </Typography>
//             <IconButton
//               edge='start'
//               color='inherit'
//               onClick={handleClose}
//               aria-label='close'
//             >
//               <CloseIcon />
//             </IconButton>
//           </Toolbar>
//         </AppBar>
//         {isLoading ? (
//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//             }}
//           >
//             <Loader size={50} />
//           </Box>
//         ) : (
//           <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 1}}>
//             <Box sx={docStyle}>
//               {data?.txt1 ? (
//                 <Box>
//                   <Typography sx={{textAlign: 'center'}} variant={'h6'}>
//                     Русская версия
//                   </Typography>
//                   <Divider />
//                   {data.txt1}
//                 </Box>
//               ) : (
//                 <Typography variant={'h3'} sx={{textAlign: 'center'}}>
//                   {t('Comparison.russian-content')}
//                 </Typography>
//               )}
//             </Box>
//             <Divider
//               orientation='vertical'
//               variant={'inset'}
//               sx={{height: 'auto', background: 'black', width: '1px', m: 0}}
//             />
//             <Box sx={docStyle}>
//               {data?.txt2 ? (
//                 <Box>
//                   <Typography sx={{textAlign: 'center'}} variant={'h6'}>
//                     Кыргыз тилинде
//                   </Typography>
//                   <Divider />
//                   {data.txt2}
//                 </Box>
//               ) : (
//                 <Typography variant={'h3'} sx={{textAlign: 'center'}}>
//                   {t('Comparison.kyrgyz-content')}
//                 </Typography>
//               )}
//             </Box>
//           </Box>
//         )}
//       </Dialog>
//     </>
//   )
// }

// const docStyle = () => ({
//   width: '50%',
//   m: 1,
//   p: 1,
//   overflow: 'auto',
//   height: '90vh',
//   '&::-webkit-scrollbar': {
//     width: '0.4em',
//   },
//   '&::-webkit-scrollbar-track': {
//     '-webkit-box-shadow': 'rgba(5,55,112,0.1)',
//   },
//   '&::-webkit-scrollbar-thumb': {
//     backgroundColor: 'rgba(7,54,110,0.1)',
//   },
// })

// export default CompareLanguages


import * as React from 'react'
import {useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import {TransitionProps} from '@mui/material/transitions'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

import {compareLang, getEditionCode} from 'src/utils/endpoints'

import {$api, Loader} from '../../../../../utils'
import { useAppSelector } from 'src/hooks'

interface Props {
  id: number
  doc: any
}

interface LangResponse {
  txt1: string
  txt2: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CompareLanguages = ({id, doc}: Props) => {
  const [open, setOpen] = React.useState(false)
  const {lng} = useAppSelector((state) => state.changeLang)
  
  // const {data, isLoading} = useQuery<LangResponse>(
  //   [id, 'doc'],
  //   async () => {
  //     return (await axios.get(`${compareLang}${id}`)).data
  //   },
  //   {enabled: open},
  // )

  const {data: edition, isLoading} = useQuery(
      [id, 'lng'],
      async () => {
        return (await $api.get(`${getEditionCode}${id}&lang=${lng === 'ru' ? 'kg' : 'ru'}`)).data
      },
      {enabled: !!id},
    )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const {t} = useTranslation()

  return (
    <>
      <Typography
        onClick={handleClickOpen}
        sx={{
          p: 1,
          cursor: 'pointer',
          '&:hover': {background: 'rgba(227,227,227,0.65)'},
        }}
      >
        {t('Comparison.comparison-of-lang')}
      </Typography>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: 'relative', height: '100px'}}>
          <Toolbar>
            <Typography sx={{ml: 2, flex: 1, color: 'white', fontSize: '20px'}}>
              {t('Comparison.comparison-of-lang')}
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: '50px',
            }}
          >
            <Loader size={50} />
          </Box>
        ) : (
          <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 1}}>
            <Box sx={docStyle}>
              {doc.contentRu ? (
                <Box>
                  <div dangerouslySetInnerHTML={{__html: doc.contentRu}} />
                </Box>
              ) : (
                <Typography variant={'h3'} sx={{textAlign: 'center'}}>
                  {t('notFoundDoc')}
                </Typography>
              )}
            </Box>
            <Divider
              orientation='vertical'
              variant={'inset'}
              sx={{height: 'auto', background: 'black', width: '1px', m: 0}}
            />
            <Box sx={docStyle}>
              {edition.contentRu ? (
                <Box>
                  <div dangerouslySetInnerHTML={{__html: edition.contentRu}} />
                </Box>
              ) : (
                <Typography variant={'h3'} sx={{textAlign: 'center'}}>
                  {t('notFoundDoc')}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Dialog>
    </>
  )
}

const docStyle = () => ({
  width: '50%',
  m: 1,
  p: 1,
  overflow: 'auto',
  height: '90vh',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'rgba(5,55,112,0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(7,54,110,0.1)',
  },
})

export default CompareLanguages
